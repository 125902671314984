const reducer = (state = {}, action) => {
  switch (action.type) {
    case 'login': //login
      return { ...state, data: action.data, loading: true };
    case 'SIGNIN_RESPONSE':
      return { ...state, usersignin: action.usersignin, loading: false };

    case 'register': // register
      return { ...state, data: action.data, loading: true };
    case 'register_response':
      return { ...state, register_res: action.register_res, loading: false };

    case 'logout': //otp request
      return { ...state, data: action.data, loading: true };
    case 'logout_response':
      return { ...state, logout_res: action.logout_res, loading: false };


    case 'requestotp': //otp request
      return { ...state, data: action.data, loading: true };
    case 'otprequest_response':
      return { ...state, otp_req_res: action.otp_req_res, loading: false };


    case 'validateotp': //otp validation
      return { ...state, data: action.data, loading: true };
    case 'validateotp_response':
      return { ...state, otp_val_res: action.otp_val_res, loading: false };


    //custom Notification send
    case 'customNotificationSend': //notification send
      return { ...state, data: action.data, loading: true };
    case 'sendNotifications_response':// receive response
      return { ...state, sendNotifications_res: action.customNotification_res, loading: false };

    // User Management starts Here 
    case 'usermanagementlist': //user management list
      return { ...state, data: action.data, loading: true };
    case 'usermanagementlist_response':
      return { ...state, usermanagement_res: action.usermanagement_res, loading: false };

    case 'inactiveUserslist': //inactive user management list
      return { ...state, data: action.data, loading: true };
    case 'inactiveUserslist_response':
      return { ...state, inactive_users_res: action.inactive_users_res, loading: false };

    case 'inactiveUsersExport': //inactive user  export 
      return { ...state, file: action.file, loading: true };
    case 'inactiveUsersExport_response':
      return { ...state, inactiveUsersExport_res: action.inactiveUsersExport_res, loading: false };
 
      
      case 'zeroStockList': //Zero Stock list
      return { ...state, data: action.data, loading: true };
      case 'zeroStockList_response':
        return { ...state, zeroStockList_res: action.zeroStockList_res, loading: false };

        case 'zeroStockExport': //Zero Stock  export 
        return { ...state, file: action.file, loading: true };
      case 'zeroStockExport_response':
        return { ...state, zeroStockExport_res: action.zeroStockExport_res, loading: false };
   
        
      case 'inactiveCustomerslist': //in active customers management list
        return { ...state, data: action.data, loading: true };
      case 'inactiveCustomerslist_response':
        return { ...state, inactive_customers_res: action.inactive_customers_res, loading: false };
          
      case 'inactiveCustomersExport': //in active user  export 
        return { ...state, file: action.file, loading: true };
      case 'inactiveCustomersExport_response':
        return { ...state, inactiveCustomersExport_res: action.inactiveCustomersExport_res, loading: false };
   
      case 'usermanagecreate': //user management create
        return { ...state, data: action.data, loading: true };
      case 'usermanagecreate_response':
      return { ...state, user_create_res: action.user_create_res, loading: false };

    case 'delete_user': //user delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_user_response':
      return { ...state, user_delete: action.user_delete, loading: false };

    case 'usersingle': //get user single 
      return { ...state, data: action.data, loading: true };
    case 'usersingle_response':
      return { ...state, user_single: action.user_single, loading: false };

    case 'usermanageupdate': // user detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'usermanageupdate_response':
      return { ...state, user_update: action.user_update, loading: false };


    case 'agent_search': //agent Search 
      return { ...state, data: action.data, loading: true };
    case 'agentsearch_response':
      return { ...state, agent_response_search: action.agent_response_search, loading: false };


    case 'useractive': // user active update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'useractive_response':
      return { ...state, useractive_update: action.useractive_update, loading: false };



    case 'user': //user Search 
      return { ...state, data: action.data, loading: true };
    case 'user_response':
      return { ...state, user_response_search: action.user_response_search, loading: false };

    case 'userExport': //User export 
      return { ...state, file: action.file, loading: true };
    case 'userExport_response':
      return { ...state, userExport_res: action.userExport_res, loading: false };

    //User management ends Here 

    //Zone Management Starts Here 
    case 'zonemanagementlist': //Zone management list
      return { ...state, data: action.data, loading: true };
    case 'zonemanagementlist_response':
      return { ...state, zonemanagement_res: action.zonemanagement_res, loading: false };

    case 'zonemanagecreate': //zone management create
      return { ...state, data: action.data, loading: true };
    case 'zonemanagecreate_response':
      return { ...state, zone_create_res: action.zone_create_res, loading: false };

    case 'delete_zone': //zone delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_zone_response':
      return { ...state, zone_delete: action.zone_delete, loading: false };

    case 'zonesingle': //get zone single 
      return { ...state, data: action.data, loading: true };
    case 'zonesingle_response':
      return { ...state, zone_single: action.zone_single, loading: false };

    case 'zonemanageupdate': // zone detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'zonemanageupdate_response':
      return { ...state, zone_update: action.zone_update, loading: false };

    case 'zone_search': //zone Search 
      return { ...state, data: action.data, loading: true };
    case 'zonesearch_response':
      return { ...state, zone_response_search: action.zone_response_search, loading: false };
    //Zone Management Ends Here 


    //uom management starts here
    case 'uommanagementlist': //uom management list
      return { ...state, data: action.data, loading: true };
    case 'uommanagementlist_response':
      return { ...state, uommanagement_res: action.uommanagement_res, loading: false };

    case 'uommanagecreate': //uom management create
      return { ...state, data: action.data, loading: true };
    case 'uommanagecreate_response':
      return { ...state, uom_create_res: action.uom_create_res, loading: false };

    case 'delete_uom': //uom delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_uom_response':
      return { ...state, uom_delete: action.uom_delete, loading: false };

    case 'uomsingle': //get uom single 
      return { ...state, data: action.data, loading: true };
    case 'uomsingle_response':
      return { ...state, uom_single: action.uom_single, loading: false };

    case 'uommanageupdate': // uom detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'uommanageupdate_response':
      return { ...state, uom_update: action.uom_update, loading: false };

    case 'uom_search': //uom Search 
      return { ...state, data: action.data, loading: true };
    case 'uomsearch_response':
      return { ...state, uom_response_search: action.uom_response_search, loading: false };

    case 'uom_product_search':// uom product search
      return { ...state, data: action.data, loading: true };
    case 'uom_product_search_response':
      return { ...state, uom_product_search_res: action.uom_product_search_res, loading: false }

    //uom management ends here

    //order failed list
    case 'orderFailedList': //uom management list
      return { ...state, data: action.data, loading: true };
    case 'orderFailedList_response':
      return { ...state, orderFailedList_res: action.orderFailedList_res, loading: false };

    //commission management starts here
    case 'commissionList': //uom management list
      return { ...state, data: action.data, loading: true };
    case 'commissionList_response':
      return { ...state, commission_res: action.commission_res, loading: false };

    case 'delete_commission': //uom delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_commission_response':
      return { ...state, commission_delete: action.commission_delete, loading: false };

    case 'commissionSingle': //get uom single 
      return { ...state, item_id: action.item_id, data: action.data, loading: true };
    case 'commissionSingle_response':
      return { ...state, commission_single: action.commission_single, loading: false };

    //commission management ends here

    //user hierarchy starts here

    case 'userHierarchyList': //user hierarchy list
      return { ...state, data: action.data, loading: true };
    case 'userHierarchyList_response':
      return { ...state, userHierarchyList_res: action.userHierarchyList_res, loading: false };

    case 'userHierarchyCreate': //user hierarchy create
      return { ...state, data: action.data, loading: true };
    case 'userHierarchyCreate_response':
      return { ...state, userHierarchyCreate_res: action.userHierarchyCreate_res, loading: false };

    case 'userHierarchyDelete': //user hierarchy delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'userHierarchyDelete_response':
      return { ...state, userHierarchyDelete_res: action.userHierarchyDelete_res, loading: false };

    case 'userHierarchySingle': //get user hierarchy single
      return { ...state, data: action.data, loading: true };
    case 'userHierarchySingle_response':
      return { ...state, userHierarchySingle_res: action.userHierarchySingle_res, loading: false };

    case 'userHierarchyUpdate': // user hierarchy detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'userHierarchyUpdate_response':
      return { ...state, userHierarchyUpdate_res: action.userHierarchyUpdate_res, loading: false };

    //user hierarchy ends here


    //Region Management Starts Here 
    case 'regionmanagementlist': //Region management list
      return { ...state, data: action.data, loading: true };
    case 'regionmanagementlist_response':
      return { ...state, regionmanagement_res: action.regionmanagement_res, loading: false };

    case 'regionmanagecreate': //Region management create
      return { ...state, data: action.data, loading: true };
    case 'regionmanagecreate_response':
      return { ...state, region_create_res: action.region_create_res, loading: false };

    case 'delete_region': //region delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_region_response':
      return { ...state, region_delete: action.region_delete, loading: false };

    case 'regionsingle': //get region single 
      return { ...state, data: action.data, loading: true };
    case 'regionsingle_response':
      return { ...state, region_single: action.region_single, loading: false };

    case 'regionmanageupdate': // region detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'regionmanageupdate_response':
      return { ...state, region_update: action.region_update, loading: false };

    case 'region_search': //region Search 
      return { ...state, data: action.data, loading: true };
    case 'regionsearch_response':
      return { ...state, region_response_search: action.region_response_search, loading: false };
    //Region Management Ends Here 


    //Territory Management Starts Here 
    case 'territorymanagementlist': //territory management list
      return { ...state, data: action.data, loading: true };
    case 'territorymanagementlist_response':
      return { ...state, territorymanagement_res: action.territorymanagement_res, loading: false };

    case 'territorymanagecreate': //territory management create
      return { ...state, data: action.data, loading: true };
    case 'territorymanagecreate_response':
      return { ...state, territory_create_res: action.territory_create_res, loading: false };

    case 'delete_territory': //territory delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_territory_response':
      return { ...state, territory_delete: action.territory_delete, loading: false };

    case 'territorysingle': //get territory single 
      return { ...state, data: action.data, loading: true };
    case 'territorysingle_response':
      return { ...state, territory_single: action.territory_single, loading: false };

    case 'territorymanageupdate': // territory detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'territorymanageupdate_response':
      return { ...state, territory_update: action.territory_update, loading: false };

    case 'territory_search': //territory Search 
      return { ...state, data: action.data, loading: true };
    case 'territorysearch_response':
      return { ...state, territory_response_search: action.territory_response_search, loading: false };
    //Territory Management Ends Here 


    //Beats Management Starts Here
    case 'beatsmanagementlist': //beats management list
      return { ...state, data: action.data, loading: true };
    case 'beatsmanagementlist_response':
      return { ...state, beatsmanagement_res: action.beatsmanagement_res, loading: false };


    case 'beatsmanagecreate': //beats management create
      return { ...state, data: action.data, loading: true };
    case 'beatsmanagecreate_response':
      return { ...state, beats_create_res: action.beats_create_res, loading: false };


    case 'delete_beats': //beats delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_beats_response':
      return { ...state, beats_delete: action.beats_delete, loading: false };


    case 'beatssingle': //get beats single 
      return { ...state, data: action.data, loading: true };
    case 'beatssingle_response':
      return { ...state, beats_single: action.beats_single, loading: false };


    case 'beatsmanageupdate': // beats detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'beatsmanageupdate_response':
      return { ...state, beats_update: action.beats_update, loading: false };


    case 'beat_search': //beat Search 
      return { ...state, data: action.data, loading: true };
    case 'beatsearch_response':
      return { ...state, beat_response_search: action.beat_response_search, loading: false };
    //Beats Management Ends Here

    //Brand Management Starts Here
    case 'brandmanagementlist': //brand management list
      return { ...state, data: action.data, loading: true };
    case 'brandmanagementlist_response':
      return { ...state, brandmanagement_res: action.brandmanagement_res, loading: false };


    case 'brandmanagecreate': //brand management create
      return { ...state, data: action.data, loading: true };
    case 'brandmanagecreate_response':
      return { ...state, brand_create_res: action.brand_create_res, loading: false };


    case 'delete_brand': //brand delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_brand_response':
      return { ...state, brand_delete: action.brand_delete, loading: false };


    case 'brandsingle': //get brand single 
      return { ...state, data: action.data, loading: true };
    case 'brandsingle_response':
      return { ...state, brand_single: action.brand_single, loading: false };


    case 'brandmanageupdate': // brand detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'brandmanageupdate_response':
      return { ...state, brand_update: action.brand_update, loading: false };


    case 'brand_search': //brand Search 
      return { ...state, data: action.data, loading: true };
    case 'brandsearch_response':
      return { ...state, brand_response_search: action.brand_response_search, loading: false };
    //Brand Management Ends Here 

    // Admin user
    case 'adminuserlist': //Admin user list
      return { ...state, data: action.data, loading: true };
    case 'adminuserlist_response':
      return { ...state, adminuser_res: action.adminuser_res, loading: false };


    case 'adminusercreate': //Admin user create
      return { ...state, data: action.data, loading: true };
    case 'adminusercreate_response':
      return { ...state, adminuser_create_res: action.adminuser_create_res, loading: false };


    case 'delete_adminuser': //Admin user delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_adminuser_response':
      return { ...state, adminuser_delete: action.adminuser_delete, loading: false };


    case 'adminusersingle': //get Admin user single 
      return { ...state, data: action.data, loading: true };
    case 'adminusersingle_response':
      return { ...state, adminuser_single: action.adminuser_single, loading: false };


    case 'adminuserupdate': // Admin user update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'adminuserupdate_response':
      return { ...state, adminuser_update: action.adminuser_update, loading: false };
    // Admin User

    //Role 
    case 'roleslist': //Role list
      return { ...state, data: action.data, loading: true };
    case 'roleslist_response':
      return { ...state, roles_res: action.roles_res, loading: false };


    case 'rolescreate': //Role create
      return { ...state, data: action.data, loading: true };
    case 'rolescreate_response':
      return { ...state, roles_create_res: action.roles_create_res, loading: false };


    case 'delete_roles': //Role delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_roles_response':
      return { ...state, roles_delete_res: action.roles_delete_res, loading: false };


    case 'rolessingle': //Role single 
      return { ...state, data: action.data, loading: true };
    case 'rolessingle_response':
      return { ...state, roles_single_res: action.roles_single_res, loading: false };


    case 'rolesupdate': // Role update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'rolesupdate_response':
      return { ...state, roles_update_res: action.roles_update_res, loading: false };

    case 'rolenamelist': //Role name list 
      return { ...state, data: action.data, loading: true };
    case 'rolenamelist_response':
      return { ...state, role_name_list_res: action.role_name_list_res, loading: false };

    case 'roleactive': //Role  active update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'roleactive_response':
      return { ...state, roleactive_update: action.roleactive_update, loading: false };
      
    // Role

    //customer Management Starts Here
    case 'customermanagementlist': //customer management list
      return { ...state, data: action.data, loading: true };
    case 'customermanagementlist_response':
      return { ...state, customermanagement_res: action.customermanagement_res, loading: false };


    case 'customermanagecreate': //Customer management create
      return { ...state, data: action.data, loading: true };
    case 'customermanagecreate_response':
      return { ...state, customer_create_res: action.customer_create_res, loading: false };


    case 'delete_customer': //customer delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_customer_response':
      return { ...state, customer_delete: action.customer_delete, loading: false };


    case 'customersingle': //get customer single 
      return { ...state, data: action.data, loading: true };
    case 'customersingle_response':
      return { ...state, customer_single: action.customer_single, loading: false };


    case 'customermanageupdate': // customer detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'customermanageupdate_response':
      return { ...state, customer_update: action.customer_update, loading: false };


    case 'customerUpload': //customer upload 
      return { ...state, file: action.file, loading: true };
    case 'customerUpload_response':
      return { ...state, customerUploadresponse: action.customerUploadresponse, loading: false };


    case 'customerexport': //customer export 
      return { ...state, file: action.file, loading: true };
    case 'customerexport_response':
      return { ...state, customerexportresponse: action.customerexportresponse, loading: false };


    case 'customer_search': //customer Search 
      return { ...state, data: action.data, loading: true };
    case 'customersearch_response':
      return { ...state, customer_response_search: action.customer_response_search, loading: false };
      
    case 'customerdropdownlist': //customer dropdown list
      return { ...state, data: action.data, loading: true };
    case 'customerdropdownlist_response':
      return { ...state, customerdropdownlist_res: action.customerdropdownlist_res, loading: false };
      //Customer Management Ends Here 


    //indirect customer list 
    //indirect customer Management Starts Here
    case 'indirectcustomermanagementlist': // indirect customer management list
      return { ...state, data: action.data, loading: true };
    case 'indirectcustomermanagementlist_response':
      return { ...state, indirectcustomermanagement_res: action.indirectcustomermanagement_res, loading: false };


    case 'indirectcustomercreate': //indirect customer management create
      return { ...state, data: action.data, loading: true };
    case 'indirectcustomercreate_response':
      return { ...state, indirectcustomercustomer_create_res: action.indirectcustomercustomer_create_res, loading: false };


    case 'delete_indirectcustomer': //indirect customer delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_indirectcustomer_response':
      return { ...state, indirectcustomer_delete: action.indirectcustomer_delete, loading: false };


    case 'wholesalerSearch': //Wholesaler list
      return { ...state, data: action.data, loading: true };
    case 'wholesalerSearch_response':
      return { ...state, wholesalerSearch_res: action.wholesalerSearch_res, loading: false };


    case 'indirectcustomersingle': //get indirect customer single 
      return { ...state, data: action.data, loading: true };
    case 'indirectcustomersingle_response':
      return { ...state, indirectcustomer_single: action.indirectcustomer_single, loading: false };


    case 'indirectcustomermanageupdate': // indirect customer detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'indirectcustomermanageupdate_response':
      return { ...state, indirectcustomer_update: action.indirectcustomer_update, loading: false };


    case 'indirectcustomerUpload': //indirect customer upload 
      return { ...state, file: action.file, loading: true };
    case 'indirectcustomerUpload_response':
      return { ...state, indirectcustomerUploadresponse: action.indirectcustomerUploadresponse, loading: false };


    case 'indirectcustomerexport': //indirect customer export 
      return { ...state, file: action.file, loading: true };
    case 'indirectcustomerexport_response':
      return { ...state, indirectcustomerexportresponse: action.indirectcustomerexportresponse, loading: false };

    case 'indirectdropdownlist': // indirect dropdown list
      return { ...state, data: action.data, loading: true };
    case 'indirectdropdownlist_response':
      return { ...state, indirectdropdownlist_res: action.indirectdropdownlist_res, loading: false };

    // stock History

    case 'stockmanagementlist': //stock  list
      return { ...state, data: action.data, loading: true };
    case 'stockmanagementlist_response':
      return { ...state, stockmanagementlist_res: action.stockmanagementlist_res, loading: false };

    case 'stocksingle': //get product single 
      return { ...state, data: action.data, loading: true };
    case 'stocksingle_response':
      return { ...state, stocksingle_res: action.stocksingle_res, loading: false };

    case 'stockmanagementupdate': // product detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'stockmanagementupdate_response':
      return { ...state, stock_update: action.stock_update, loading: false };

    case 'stockHistory_list': //stock History list
      return { ...state, data: action.data, loading: true };
    case 'stockHistory_list_response':
      return { ...state, stockHistory_list_res: action.stockHistory_list_res, loading: false };

    case 'stockHistory_get': //get stock History single 
      return { ...state, data: action.data, loading: true };
    case 'stockHistory_get_response':
      return { ...state, stockHistory_get_res: action.stockHistory_get_res, loading: false };

    //Product Management Starts Here 
    case 'productmanagementlist': //product management list
      return { ...state, data: action.data, loading: true };
    case 'productmanagementlist_response':
      return { ...state, productmanagement_res: action.productmanagement_res, loading: false };


    case 'productmanagecreate': //product management create
      return { ...state, data: action.data, loading: true };
    case 'productmanagecreate_response':
      return { ...state, product_create_res: action.product_create_res, loading: false };


    case 'delete_product': //product delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_product_response':
      return { ...state, product_delete: action.product_delete, loading: false };


    case 'productsingle': //get product single 
      return { ...state, data: action.data, loading: true };
    case 'productsingle_response':
      return { ...state, product_single: action.product_single, loading: false };


    case 'productmanageupdate': // product detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'productmanageupdate_response':
      return { ...state, product_update: action.product_update, loading: false };



    case 'productUpload': //product upload 
      return { ...state, file: action.file, loading: true };
    case 'productUpload_response':
      return { ...state, productUploadresponse: action.productUploadresponse, loading: false };


    case 'productexport': //product export 
      return { ...state, file: action.file, loading: true };
    case 'productexport_response':
      return { ...state, productexportresponse: action.productexportresponse, loading: false };
    
    case 'productdropdownlist': //Product dropdown list
      return { ...state, data: action.data, loading: true };
    case 'productdropdownlist_response':
      return { ...state, productdropdownlist_res: action.productdropdownlist_res, loading: false };

      //Product Management Ends Here 

    case 'companylist': //Company List
      return { ...state, data: action.data, loading: true };
    case 'companylist_response':
      return { ...state, companylist_res: action.companylist_res, loading: false };


    case 'stockUpload': //stock upload 
      return { ...state, file: action.file, loading: true };
    case 'stockUpload_response':
      return { ...state, stockUploadresponse: action.stockUploadresponse, loading: false };


    case 'stockexport': //stock export 
      return { ...state, data: action.data, loading: true };
    case 'stockexport_response':
      return { ...state, stockexportresponse: action.stockexportresponse, loading: false };



    //order management starts here
    case 'ordermanagementlist': //order management list
      return { ...state, data: action.data, loading: true };
    case 'ordermanagementlist_response':
      return { ...state, ordermanagement_res: action.ordermanagement_res, loading: false };

    case 'routeOrdermanagementlist': //order for route
      return { ...state, data: action.data, loading: true };
    case 'routeOrdermanagementlist_response':
      return { ...state, routeOrder_res: action.routeOrder_res, loading: false };

    case 'routeOrdermanagementlist2': //order for route
      return { ...state, data: action.data, loading: true };
    case 'routeOrdermanagementlist2_response':  
      return { ...state, routeOrder_res2: action.routeOrder_res2, loading: false };

    case 'orderexport': //order export 
      return { ...state, data: action.data, loading: true }; 
    case 'orderexport_response':
      return { ...state, orderexportresponse: action.orderexportresponse, loading: false };

    case 'multipleOrderExport': //multiple order export  
      return { ...state, data: action.data, loading: true };
    case 'multipleorderexport_response':
      return { ...state, multipleorderexportresponse: action.multipleorderexportresponse, loading: false };

    case 'orderSingle': //get order single 
      return { ...state, data: action.data, loading: true };
    case 'orderSingle_response':
      return { ...state, order_single: action.order_single, loading: false };

    case 'orderNoList': //get order single 
      return { ...state, data: action.data, loading: true };
    case 'order_no_list_response':
      return { ...state, order_no_list_res: action.order_no_list_res, loading: false };

    case 'delete_order': //order delete 
      return { ...state, data: action.item_id, loading: true };
    case 'delete_order_response':
      return { ...state, order_delete: action.order_delete, loading: false };

    case 'order_pdf': //order pdf 
      return { ...state, data: action.item_id, loading: true };
    case 'order_pdf_response':
      return { ...state, order_pdf_res: action.order_pdf_res, loading: false };

    case 'orderCreate': //order create 
      return { ...state, data: action.item_id, loading: true };
    case 'order_create_response':
      return { ...state, order_create: action.order_create, loading: false };

    case 'orderUpdate': // product detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'orderUpdate_response':
      return { ...state, order_update: action.order_update, loading: false };

    case 'orderReject': // product detail reject
      return { ...state, id: action.id, loading: true };
    case 'orderReject_response':
      return { ...state, order_reject: action.order_reject, loading: false };

    case 'outstandingDays':
      return { ...state, id: action.id, loading: true };
    case 'outstandingDays_Response':
      return { ...state, outstandingDaysResponse: action.outstandingDaysResponse, loading: false };

    case 'remainingOutstandingDays': //outstandingDays for order
      return { ...state, id: action.id, loading: true };
    case 'remainingOutstandingDays_Response':
      return { ...state, remainingOutstandingDays_res: action.remainingOutstandingDays_res, loading: false };
    
    case 'orderProduct': //get order product get 
      return { ...state, data: action.data, loading: true };
    case 'orderProduct_response':
      return { ...state, orderproduct_res: action.orderproduct_res, loading: false };
        //order management ends here 

    // User  dashboard count starts Here 
    case 'usersdashboard': //user dashboard count
      return { ...state, data: action.data, loading: true };
    case 'usersdashboard_response':
      return { ...state, usersdashboard_res: action.usersdashboard_res, loading: false };

    case 'product_top_10': //user dashboard Top 10 product
      return { ...state, data: action.data, loading: true };
    case 'product_top_10_response':
      return { ...state, product_top_10_res: action.product_top_10_res, loading: false };

    case 'product_least_10': //user dashboard least 10 product
      return { ...state, data: action.data, loading: true };
    case 'product_least_10_response':
      return { ...state, product_least_10_res: action.product_least_10_res, loading: false };

    case 'customers_top_10': //user dashboard Top 10 customers
      return { ...state, data: action.data, loading: true };
    case 'customers_top_10_response':
      return { ...state, customers_top_10_res: action.customers_top_10_res, loading: false };

    case 'customers_visit': // Customers Visits list
      return { ...state, data: action.data, loading: true };
    case 'customers_visit_response':
      return { ...state, customers_visit_res: action.customers_visit_res, loading: false };

    case 'customers_visit_export': // Customers Visits export
      return { ...state, data: action.data, loading: true };
    case 'customers_visit_export_response':
      return { ...state, customers_visit_export_res: action.customers_visit_export_res, loading: false };

    case 'customers_least_10': //user dashboard least 10 customers
      return { ...state, data: action.data, loading: true };
    case 'customers_least_10_response':
      return { ...state, customers_least_10_res: action.customers_least_10_res, loading: false };

    case 'orders_top_10': //user dashboard Top 10 orders
      return { ...state, data: action.data, loading: true };
    case 'orders_top_10_response':
      return { ...state, orders_top_10_res: action.orders_top_10_res, loading: false };

    case 'orders_least_10': //user dashboard least 10 orders
      return { ...state, data: action.data, loading: true };
    case 'orders_least_10_response':
      return { ...state, orders_least_10_res: action.orders_least_10_res, loading: false };

    case 'changePassword': //change password
      return { ...state, data: action.data, loading: true };
    case 'receive_changePassword_data':
      return { ...state, changePassword_response: action.changePassword_response, loading: false };

    case 'changeEmailConfig': //email config update
      return { ...state, data: action.data, loading: true };
    case 'receive_emailConfig_update':
      return { ...state, changeEmailConfig_res: action.changeEmailConfig_res, loading: false };

    case 'profilesingle': //get product single 
      return { ...state, data: action.data, loading: true };
    case 'profilesingle_response':
      return { ...state, profile_single: action.profile_single, loading: false };

    case 'updateprofile': // profile detail update
      return { ...state, data: action.data, loading: true };
    case 'updateprofile_response':
      return { ...state, profile_update: action.profile_update, loading: false };


    case 'companyConfigSingle': //get company config single 
      return { ...state, data: action.data, loading: true };
    case 'companyConfigSingle_response':
      return { ...state, companyConfigSingle_res: action.companyConfigSingle_res, loading: false };

    case 'updatecompanyconfig': // Company Config update
      return { ...state, data: action.data, loading: true };
    case 'updatecompanyconfig_response':
      return { ...state, companyconfig_update: action.companyconfig_update, loading: false };

    case 'generatesecretkey': // Company generate secret key
      return { ...state, data: action.data, loading: true };
    case 'generatesecretkey_response':
      return { ...state, generatesecretkey_res: action.generatesecretkey_res, loading: false };

    //address Dropdown 
    case 'country_search': //country Search 
      return { ...state, data: action.data, loading: true };
    case 'countrysearch_response':
      return { ...state, country_response_search: action.country_response_search, loading: false };

    case 'state_search': //state Search 
      return { ...state, data: action.data, loading: true };
    case 'statesearch_response':
      return { ...state, state_response_search: action.state_response_search, loading: false };


    case 'district_search': //district Search 
      return { ...state, data: action.data, loading: true };
    case 'districtsearch_response':
      return { ...state, district_response_search: action.district_response_search, loading: false };


    case 'city_search': //city Search 
      return { ...state, data: action.data, loading: true };
    case 'citysearch_response':
      return { ...state, city_response_search: action.city_response_search, loading: false };


    //Route Management Starts here
    case 'routecreate': //route management create
      return { ...state, data: action.data, loading: true };
    case 'routecreate_response':
      return { ...state, route_create_res: action.route_create_res, loading: false };


    case 'routemanagementlist': //route management list
      return { ...state, data: action.data, loading: true };
    case 'routemanagementlist_response':
      return { ...state, routemanagement_res: action.routemanagement_res, loading: false };

    case 'delete_route': //route delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_route_response':
      return { ...state, route_delete: action.route_delete, loading: false };


    case 'routesingle': //get route single 
      return { ...state, data: action.data, loading: true };
    case 'routesingle_response':
      return { ...state, route_single: action.route_single, loading: false };


    case 'routeupdate': //Route management create
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'routeupdate_response':
      return { ...state, route_update: action.route_update, loading: false };


    case 'closeTour': //Tour Closure
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'closeTour_response':
      return { ...state, closeTourRes_data: action.closeTourRes_data, loading: false };

    //Route Management Ends here 

    //outstading
    case 'outstandingUpload': //outstanding export 
      return { ...state, data: action.data, loading: true };
    case 'outstandingUpload_response':
      return { ...state, outstandingUploadresponse: action.outstandingUploadresponse, loading: false };


    case 'outstandlist': //outstanding management list
      return { ...state, data: action.data, loading: true };
    case 'outstandlist_response':
      return { ...state, outstand_res: action.outstand_res, loading: false };

      case 'outstandingpartyname_list': // outstanding party name list 
      return { ...state, data: action.data, loading: true };
    case 'outstandingpartyname_list_response':
      return { ...state, outstandingpartyname_list_res: action.outstandingpartyname_list_res, loading: false };

    //disptach
    case 'dispatchlist': //dispatch management list
      return { ...state, data: action.data, loading: true }; 
    case 'dispatchlist_response':
      return { ...state, dispatch_res: action.dispatch_res, loading: false };


    case 'dispatchUpload': //dispatch export 
      return { ...state, data: action.data, loading: true };
    case 'dispatchUpload_response':
      return { ...state, dispatchUploadresponse: action.dispatchUploadresponse, loading: false };

    case 'dispatchStatus': //dispatch export 
      return { ...state, data: action.data, loading: true };
    case 'dispatchStatus_response':
      return { ...state, dispatchStatusresponse: action.dispatchStatusresponse, loading: false };

    case 'dispatch_user_list': // Dispatch User Search 
      return { ...state, data: action.data, loading: true };
    case 'dispatch_user_list_response':
      return { ...state, dispatch_user_list_res: action.dispatch_user_list_res, loading: false };

      case 'dispatchpartyname_list': // Dispatch party name Search 
      return { ...state, data: action.data, loading: true };
    case 'dispatchpartyname_list_response':
      return { ...state, dispatchpartyname_list_res: action.dispatchpartyname_list_res, loading: false };


    case 'outstanding_user_list': // Outstanding User Search 
      return { ...state, data: action.data, loading: true };
    case 'outstanding_user_list_response':
      return { ...state, outstanding_user_list_res: action.outstanding_user_list_res, loading: false };


    case 'versionUpdate': // app version Update
      return { ...state, data: action.data, versionId: action.versionId, loading: true };
    case 'versionUpdate_response':
      return { ...state, version_update: action.version_update, loading: false };

    //rate management
    case 'ratelist': //rate List 
      return { ...state, data: action.data, loading: true };
    case 'rate_res':
      return { ...state, rate_response: action.rate_response, loading: false };


    case 'ratecreate': //rate Create 
      return { ...state, data: action.data, loading: true };
    case 'rate_create_response':
      return { ...state, rate_create_res: action.rate_create_res, loading: false };


    case 'delete_rate': //rate delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_rate_response':
      return { ...state, rate_delete: action.rate_delete, loading: false };


    case 'ratesingle': //get rate single 
      return { ...state, data: action.data, loading: true };
    case 'ratesingle_response':
      return { ...state, rate_single: action.rate_single, loading: false };


    case 'rateupdate': // Rate detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'rateupdate_response':
      return { ...state, rate_update: action.rate_update, loading: false };

    //Sr. ASM Assignment Create
    case 'srAsmUserAssign': //Sr ASM Assign Create 
      return { ...state, data: action.data, loading: true };
    case 'srAsmUserAssign_response':
      return { ...state, srasmassigncreate: action.srasmassigncreate, loading: false };

    case 'srAsmAssign_wholesalerdropdownlist': //wholesaler dropdown list
      return { ...state, data: action.data, loading: true };
    case 'srAsmAssign_wholesalerdropdownlist_response':
      return { ...state, srAsmAssign_wholesalerdropdownlist_res: action.srAsmAssign_wholesalerdropdownlist_res, loading: false };

    case 'srasmassignList': //Sr ASM Assign List 
      return { ...state, data: action.data, loading: true };
    case 'srasmassignList_res':
      return { ...state, srasmassignList_response: action.srasmassignList_response, loading: false };


    case 'delete_srAsmAssign': //Sr ASM Assign delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_srAsmAssign_response':
      return { ...state, srAsmAssign_delete_response: action.srAsmAssign_delete_response, loading: false };

    case 'SrAsmsingle': //get Sr ASM Assign single 
      return { ...state, data: action.data, loading: true };
    case 'SrAsmsingle_response':
      return { ...state, srAsm_single: action.srAsm_single, loading: false };

    case 'srAsmUserAssignUpdate': // Rate detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'srAsmUserAssignUpdate_response':
      return { ...state, srAsmUserAssign_update: action.srAsmUserAssign_update, loading: false };

    case 'orderConfirm': // Pack Order
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'orderConfirm_response':
      return { ...state, orderConfirm_pack: action.orderConfirm_pack, loading: false };

    case 'unitCodeByShade': //get Sr ASM Assign single 
      return { ...state, data: action.data, loading: true };
    case 'unitCodeByShade_response':
      return { ...state, unitCodeByShade_recieve: action.unitCodeByShade_recieve, loading: false };

    case 'srasmassigndrpdownList': //Sr ASM Assign dropdown list 
      return { ...state, data: action.data, loading: true };
    case 'srasmassigndrpdownList_response': 
      return { ...state, srasmassigndrpdownList_res: action.srasmassigndrpdownList_res, loading: false };

    //Scheme management
    case 'schemecreate': //Scheme management create
      return { ...state, data: action.data, loading: true };
    case 'schemecreate_response':
      return { ...state, scheme_create_res: action.scheme_create_res, loading: false };

    case 'schemelist': //Scheme management list
      return { ...state, data: action.data, loading: true };
    case 'schemelist_response':
      return { ...state, scheme_res: action.scheme_res, loading: false };


    case 'delete_scheme': //Scheme delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_scheme_response':
      return { ...state, scheme_delete: action.scheme_delete, loading: false };


    case 'schemesingle': //get scheme single 
      return { ...state, data: action.data, loading: true };
    case 'schemesingle_response':
      return { ...state, scheme_single: action.scheme_single, loading: false };


    case 'schemeupdate': // zone detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'schemeupdate_response':
      return { ...state, scheme_update: action.scheme_update, loading: false };

    case 'schemesearchlist': // zone  scheme  
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'schemesearchlist_response':
      return { ...state, schemesearchlist_res: action.schemesearchlist_res, loading: false };



    //Transpoter Management
    case 'transportercreate': //create
      return { ...state, data: action.data, loading: true };
    case 'transportercreate_response':
      return { ...state, transporter_create_res: action.transporter_create_res, loading: false };


    case 'transporterlist': // list
      return { ...state, data: action.data, loading: true };
    case 'transporterlist_response':
      return { ...state, transporter_res: action.transporter_res, loading: false };


    case 'delete_transporter': // delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_transporter_response':
      return { ...state, transporter_delete: action.transporter_delete, loading: false };


    case 'transportersingle': //  single 
      return { ...state, data: action.data, loading: true };
    case 'transportersingle_response':
      return { ...state, transporter_single: action.transporter_single, loading: false };


    case 'transporterupdate': //  update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'transporterupdate_response':
      return { ...state, transporter_update: action.transporter_update, loading: false };


    case 'transporterUpload': //upload 
      return { ...state, file: action.file, loading: true };
    case 'transporterUpload_response':
      return { ...state, transporterUploadresponse: action.transporterUploadresponse, loading: false };

    case 'transporterdropdownlist': //dropdown list 
      return { ...state, file: action.file, loading: true };
    case 'transporterdropdownlist_response':
      return { ...state, transporterdropdownlist_res: action.transporterdropdownlist_res, loading: false };

    //Reatiler Module
    case 'retailerCreate': // create
      return { ...state, data: action.data, loading: true };
    case 'retailerCreate_response':
      return { ...state, retailer_create_res: action.retailer_create_res, loading: false };

    case 'retailerlist': //retailer list
      return { ...state, data: action.data, loading: true };
    case 'retailerlist_response':
      return { ...state, retailerlist_res: action.retailerlist_res, loading: false };

    case 'delete_retailer': //Retailer delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_retailer_response':
      return { ...state, retailer_delete: action.retailer_delete, loading: false };

    case 'retailersingle': //get Retailer single 
      return { ...state, data: action.data, loading: true };
    case 'retailersingle_response':
      return { ...state, retailer_single: action.retailer_single, loading: false };

    case 'retailermanageupdate': // Retailer detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'retailermanageupdate_response':
      return { ...state, retailer_update: action.retailer_update, loading: false };


    //Category Module 
    case 'categorylist': // list
      return { ...state, data: action.data, loading: true };
    case 'categorylist_response':
      return { ...state, categorylist_res: action.categorylist_res, loading: false };

    case 'categoryCreate': // create
      return { ...state, data: action.data, loading: true };
    case 'categoryCreate_response':
      return { ...state, category_create_res: action.category_create_res, loading: false };

    case 'delete_category': //Category delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_category_response':
      return { ...state, category_delete: action.category_delete, loading: false };

    case 'categorySingle': //get Category single 
      return { ...state, data: action.data, loading: true };
    case 'categorySingle_response':
      return { ...state, category_single: action.category_single, loading: false };

    case 'categoryUpdate': // Category detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'categoryUpdate_response':
      return { ...state, category_update: action.category_update, loading: false };

    case 'category_search': //Category Search 
      return { ...state, data: action.data, loading: true };
    case 'categorysearch_response':
      return { ...state, category_response_search: action.category_response_search, loading: false };


    //Trending 
    case 'trendlist': // list
      return { ...state, data: action.data, loading: true };
    case 'trendlist_response':
      return { ...state, trendlist_res: action.trendlist_res, loading: false };


    case 'trendCreate': // create
      return { ...state, data: action.data, loading: true };
    case 'trendCreate_response':
      return { ...state, trend_create_res: action.trend_create_res, loading: false };



    case 'delete_trend': //trend delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_trend_response':
      return { ...state, trend_delete: action.trend_delete, loading: false };


    case 'trendsingle': //get single 
      return { ...state, data: action.data, loading: true };
    case 'trendsingle_response':
      return { ...state, trend_single: action.trend_single, loading: false };


    case 'trendUpdate': // trend update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'trendUpdate_response':
      return { ...state, trend_update: action.trend_update, loading: false };




    //E-catelogue Module 
    case 'cataloguelist': // list
      return { ...state, data: action.data, loading: true };
    case 'cataloguelist_response':
      return { ...state, cataloguelist_res: action.cataloguelist_res, loading: false };



    case 'catalogueCreate': // create
      return { ...state, data: action.data, loading: true };
    case 'catalogueCreate_response':
      return { ...state, catalogue_create_res: action.catalogue_create_res, loading: false };


    case 'cataloguesingle': // single 
      return { ...state, data: action.data, loading: true };
    case 'cataloguesingle_response':
      return { ...state, catalogue_single: action.catalogue_single, loading: false };


    case 'delete_catalogue': //catalogue delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_catalogue_response':
      return { ...state, catalogue_delete: action.catalogue_delete, loading: false };


    case 'catalogueupdate': // catalogue update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'catalogueupdate_response':
      return { ...state, catalogue_update: action.catalogue_update, loading: false };



    //mis Report
    case 'graphDataReq': // mis graph 
      return { ...state, data: action.data, loading: true };
    case 'graphDataReq_response':
      return { ...state, graphData: action.graphData, loading: false };

    //mis Report graph 2
    case 'graphDataReq2': // mis graph 
      return { ...state, data: action.data, loading: true };
    case 'graphDataReq2_response':
      return { ...state, graphDataReq2_res: action.graphDataReq2_res, loading: false };

    case 'misReportTableReq': // mis table 
      return { ...state, data: action.data, loading: true };
    case 'misReportTableReq_response':
      return { ...state, misTableData: action.misTableData, loading: false };

    case 'misReportTableReq2': // mis table view
      return { ...state, data: action.data, loading: true };
    case 'misReportTableReq2_response':
      return { ...state, misTableData2: action.misTableData2, loading: false };

    case 'misSettingSingle': // mis setting single 
      return { ...state, data: action.data, loading: true };
    case 'misSettingSingle_response':
      return { ...state, misSingleSetting: action.misSingleSetting, loading: false };


    case 'misSettingUpdate': // mis Setting Update
      return { ...state, data: action.data, loading: true };
    case 'misSettingUpdate_response':
      return { ...state, misSetting_update: action.misSetting_update, loading: false };


    case 'misPdfExport': // mis PDF Report
      return { ...state, data: action.data, loading: true };
    case 'misPdfExport_response':
      return { ...state, misPdfExportData: action.misPdfExportData, loading: false };


    //Company
    case 'companyCreate': // create 
      return { ...state, data: action.data, loading: true };
    case 'companyCreate_response':
      return { ...state, companyData: action.companyData, loading: false };


    case 'companySingle': // single 
      return { ...state, data: action.data, loading: true };
    case 'companySingle_response':
      return { ...state, singleCompanyData: action.singleCompanyData, loading: false };


    case 'companyUpdate': // company update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'companyUpdate_response':
      return { ...state, company_update: action.company_update, loading: false };




    //discount 
    case 'discountCreate': // create 
      return { ...state, data: action.data, loading: true };
    case 'discountCreate_response':
      return { ...state, discountCreateRes: action.discountCreateRes, loading: false };


    case 'discountList': // list
      return { ...state, data: action.data, loading: true };
    case 'discountList_response':
      return { ...state, discountList_res: action.discountList_res, loading: false };


    case 'dicountDelete': //delete  
      return { ...state, id: action.id, loading: true };
    case 'dicountDelete_response':
      return { ...state, discount_delete: action.discount_delete, loading: false };


    case 'discountSingle': //single  
      return { ...state, id: action.id, loading: true };
    case 'discountSingle_response':
      return { ...state, discount_singleRes: action.discount_singleRes, loading: false };


    case 'discountUpdate': // discount update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'discountUpdate_response':
      return { ...state, discountUpdateRes: action.discountUpdateRes, loading: false };


    //agency
    case 'agencyCreate': //create
      return { ...state, data: action.data, loading: true };
    case 'agencyCreate_response':
      return { ...state, agencyCreateRes: action.agencyCreateRes, loading: false };


    case 'agencyList': //List
      return { ...state, data: action.data, loading: true };
    case 'agencyList_response':
      return { ...state, agencyListRes: action.agencyListRes, loading: false };



    case 'delete_agency': //delete  
      return { ...state, id: action.id, loading: true };
    case 'delete_agency_response':
      return { ...state, delete_agencyRes: action.delete_agencyRes, loading: false };


    case 'agencySingle': //single  
      return { ...state, id: action.id, loading: true };
    case 'agencySingle_response':
      return { ...state, agency_Single: action.agency_Single, loading: false };


    case 'agencyUpdate': // company update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'agencyUpdate_response':
      return { ...state, agencyUpdateRes: action.agencyUpdateRes, loading: false };


    case 'agencyUpload': //agency upload 
      return { ...state, file: action.file, loading: true };
    case 'agencyUpload_response':
      return { ...state, agencyUploadresponse: action.agencyUploadresponse, loading: false };

    // package starts

    case 'package_create': // create
      return { ...state, data: action.data, loading: true }
    case 'package_create_response':
      return { ...state, package_create_res: action.package_create_res, loading: false }

    case 'package_list': // List
      return { ...state, data: action.data, loading: true }
    case 'package_list_response':
      return { ...state, package_list_res: action.package_list_res, loading: false }

    case 'package_public_list': // Public List
      return { ...state, data: action.data, loading: true }
    case 'package_public_list_response':
      return { ...state, package_public_list_res: action.package_public_list_res, loading: false }

    case 'package_delete': // delete
      return { ...state, data: action.data, loading: true }
    case 'package_delete_response':
      return { ...state, package_delete_res: action.package_delete_res, loading: false }

    case 'package_get': // Single
      return { ...state, data: action.data, loading: true }
    case 'package_get_response':
      return { ...state, package_get_res: action.package_get_res, loading: false }

    case 'package_update': // Update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'package_update_response':
      return { ...state, package_update_res: action.package_update_res, loading: false }

    case 'package_search': // Search
      return { ...state, data: action.data, loading: true }
    case 'package_search_response':
      return { ...state, package_search_res: action.package_search_res, loading: false }

    // package ends

    //Expense Catagory
    case 'expenseCatagoryCreate': //create
      return { ...state, data: action.data, loading: true }
    case 'expenseCatagoryCreate_response':
      return { ...state, expenseCatagoryResponseData: action.expenseCatagoryResponseData, loading: false }

    case 'expenseCatagoryList': //List
      return { ...state, data: action.data, loading: true }
    case 'expenseCatagoryList_response':
      return { ...state, expenseCatagoryListData: action.expenseCatagoryListData, loading: false }

    case 'delete_ExpenseCatagory': //delete
      return { ...state,id: action.id, data: action.data, loading: true }
    case 'delete_ExpenseCatagory_response':
      return { ...state, delete_ExpenseCatagoryData: action.delete_ExpenseCatagoryData, loading: false }

    case 'singleExpenseCategory': //Single
      return { ...state, data: action.data, loading: true }
    case 'singleExpenseCategory_response':
      return { ...state, singleExpenseCategoryData: action.singleExpenseCategoryData, loading: false }

    case 'updateExpenseCategory': //Update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'updateExpenseCategory_response':
      return { ...state, expenseCategoryUpdateRes: action.expenseCategoryUpdateRes, loading: false }

    case 'expenseCategorySearch': //Search
      return { ...state, data: action.data, loading: true }
    case 'expenseCategorySearch_response':
      return { ...state, expenseCategorySearchData: action.expenseCategorySearchData, loading: false }

    case 'expenseApprovalList': //expense Approval List
      return { ...state, data: action.data, loading: true }
    case 'expenseApprovalList_response':
      return { ...state, expenseApprovalListData: action.expenseApprovalListData, loading: false }

    case 'approvalRequest': //Expense Approval Request
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'approvalRequest_response':
      return { ...state, expenseApproval_Res: action.expenseApproval_Res, loading: false }

    case 'expense_export': //expense export 
      return { ...state, file: action.file, loading: true };
    case 'expense_export_response':
      return { ...state, expense_export_res: action.expense_export_res, loading: false };

    case 'advanceList': //expense Approval List
      return { ...state, data: action.data, loading: true }
    case 'advanceList_response':
      return { ...state, advanceListData: action.advanceListData, loading: false }


    case 'advanceAction': //expense Approval List
      return { ...state, data: action.data, id: action.id, loading: true }
    case 'advanceAction_response':
      return { ...state, advanceActionData: action.advanceActionData, loading: false }


    case 'advanceManyAction': //Action on many
      return { ...state, data: action.data, loading: true }
    case 'advanceManyAction_response':
      return { ...state, advanceManyActionData: action.advanceManyActionData, loading: false }

    case 'advance_export': //advance export 
      return { ...state, file: action.file, loading: true };
    case 'advance_export_response':
      return { ...state, advance_export_res: action.advance_export_res, loading: false };

    case 'touringVoucherList': //Action on many
      return { ...state, data: action.data, loading: true }
    case 'touringVoucherList_response':
      return { ...state, touringVoucherListData: action.touringVoucherListData, loading: false }


    case 'touringCSV': //Action on many
      return { ...state, data: action.data, loading: true }
    case 'touringCSV_response':
      return { ...state, touringCSVData: action.touringCSVData, loading: false }

    case 'logsList': //expense Logs List
      return { ...state, data: action.data, loading: true }
    case 'logical_logs_response':
      return { ...state, logsListData: action.logicalListData, loading: false }

    //commission range starts here

    case 'commissionRange': //commission range list
      return { ...state, data: action.data, loading: true };
    case 'commissionRange_response':
      return { ...state, commissionRange_res: action.commissionRange_res, loading: false };

    case 'commissionCreate': //commission range create
      return { ...state, data: action.data, loading: true };
    case 'commissionRangeCreate_response':
      return { ...state, commissionRangeCreate_res: action.commissionRangeCreate_res, loading: false };

    case 'commissionRangeDelete': //commission range delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'commissionRangeDelete_response':
      return { ...state, commissionRangeDelete_res: action.commissionRangeDelete_res, loading: false };

    case 'commissionRangeSingle': //get commission range single 
      return { ...state, data: action.data, loading: true };
    case 'commissionRangeSingle_response':
      return { ...state, commissionRangeSingle_res: action.commissionRangeSingle_res, loading: false };

    case 'commissionRangeUpdate': // commission detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'commissionRangeUpdate_response':
      return { ...state, commissionRangeUpdate_res: action.commissionRangeUpdate_res, loading: false };

    //commission range ends here

    //country management starts here
    case 'country_list': //country list
      return { ...state, data: action.data, loading: true };
    case 'country_list_response':
      return { ...state, country_list_res: action.country_list_res, loading: false };

    case 'country_create': //country create
      return { ...state, data: action.data, loading: true };
    case 'country_create_response':
      return { ...state, country_create_res: action.country_create_res, loading: false };

    case 'country_delete': //country delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'country_delete_response':
      return { ...state, country_delete_res: action.country_delete_res, loading: false };

    case 'country_get': //country get single 
      return { ...state, data: action.data, loading: true };
    case 'country_get_response':
      return { ...state, country_get_res: action.country_get_res, loading: false };

    case 'country_update': // country update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'country_update_response':
      return { ...state, country_update_res: action.country_update_res, loading: false };

    //country ends here

    //state management starts here
    case 'state_list': //state list
      return { ...state, data: action.data, loading: true };
    case 'state_list_response':
      return { ...state, state_list_res: action.state_list_res, loading: false };

    case 'state_create': //state create
      return { ...state, data: action.data, loading: true };
    case 'state_create_response':
      return { ...state, state_create_res: action.state_create_res, loading: false };

    case 'state_delete': //state delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'state_delete_response':
      return { ...state, state_delete_res: action.state_delete_res, loading: false };

    case 'state_get': //state get single 
      return { ...state, data: action.data, loading: true };
    case 'state_get_response':
      return { ...state, state_get_res: action.state_get_res, loading: false };

    case 'state_update': // state update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'state_update_response':
      return { ...state, state_update_res: action.state_update_res, loading: false };

    //state ends here

    //city management starts here
    case 'city_list': //city list
      return { ...state, data: action.data, loading: true };
    case 'city_list_response':
      return { ...state, city_list_res: action.city_list_res, loading: false };

    case 'city_create': //city create
      return { ...state, data: action.data, loading: true };
    case 'city_create_response':
      return { ...state, city_create_res: action.city_create_res, loading: false };

    case 'city_delete': //city delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'city_delete_response':
      return { ...state, city_delete_res: action.city_delete_res, loading: false };

    case 'city_get': //city get single 
      return { ...state, data: action.data, loading: true };
    case 'city_get_response':
      return { ...state, city_get_res: action.city_get_res, loading: false };

    case 'city_update': // city update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'city_update_response':
      return { ...state, city_update_res: action.city_update_res, loading: false };

    //city ends here

    //payment module starts here

    case 'paymentlist': // payment list
      return { ...state, data: action.data, loading: true };
    case 'paymentlist_response':
      return { ...state, payment_res: action.payment_res, loading: false };

    case 'paymentSingle': // payment details get
      return { ...state, id: action.id, loading: true };
    case 'paymentSingle_response':
      return { ...state, paymentSingle_res: action.paymentSingle_res, loading: false };

    case 'partynamelist': // partyname list
      return { ...state, data: action.data, loading: true };
    case 'partynamelist_response':
      return { ...state, partynamelist_res: action.partynamelist_res, loading: false };

    case 'invoicenumlist': // invoicenumber list
      return { ...state, data: action.data, loading: true };
    case 'invoicenumlist_response':
      return { ...state, invoicenumlist_res: action.invoicenumlist_res, loading: false };

    case 'paymentExport': // Payment export 
      return { ...state, data: action.data, loading: true };
    case 'paymentExport_response':
      return { ...state, paymentExp_res: action.paymentExp_res, loading: false };

    case 'delete_payment': // Payment delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_payment_response':
      return { ...state, payment_delete_res: action.payment_delete_res, loading: false };

    case 'status_payment': // Payment status  
      return { ...state, item_id: action.item_id, status: action.status, loading: true };
    case 'status_payment_response':
      return { ...state, payment_status_res: action.payment_status_res, loading: false };

    //payment module ends here

    //feedback module starts here

    case 'feedbacklist': // feedback list
      return { ...state, data: action.data, loading: true };
    case 'feedbacklist_response':
      return { ...state, feedback_res: action.feedback_res, loading: false };

    case 'feedbackSingle': // feedback details get
      return { ...state, id: action.id, loading: true };
    case 'feedbackSingle_response':
      return { ...state, feedbackSingle_res: action.feedbackSingle_res, loading: false };

    case 'feedbackExport': // feedback export 
      return { ...state, data: action.data, loading: true };
    case 'feedbackExport_response':
      return { ...state, feedbackExp_res: action.feedbackExp_res, loading: false };

    case 'delete_feedback': // feedback delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'delete_feedback_response':
      return { ...state, feedback_delete_res: action.feedback_delete_res, loading: false };

    case 'status_feedback': // feedback status  
      return { ...state, item_id: action.item_id, status: action.status, loading: true };
    case 'status_feedback_response':
      return { ...state, feedback_status_res: action.feedback_status_res, loading: false };

    //feedback module ends here

    //options version starts here
    case 'versionGet': //single  
      return { ...state, data: action.data, loading: true };
    case 'versionGet_response':
      return { ...state, versionGet_res: action.versionGet_res, loading: false };

    case 'versionUpdateCall': // company update
      return { ...state, data: action.data, loading: true };
    case 'versionUpdateCall_response':
      return { ...state, versionUpdateCall_res: action.versionUpdateCall_res, loading: false };
    //options version ends here

    //orderTemplate starts here
    case 'orderTemplate_list': //orderTemplate list
      return { ...state, data: action.data, loading: true };
    case 'orderTemplate_list_response':
      return { ...state, orderTemplate_list_res: action.orderTemplate_list_res, loading: false };

    case 'orderTemplate_create': //orderTemplate create
      return { ...state, data: action.data, loading: true };
    case 'orderTemplate_create_response':
      return { ...state, orderTemplate_create_res: action.orderTemplate_create_res, loading: false };

    case 'orderTemplate_delete': //orderTemplate delete  
      return { ...state, item_id: action.item_id, loading: true };
    case 'orderTemplate_delete_response':
      return { ...state, orderTemplate_delete_res: action.orderTemplate_delete_res, loading: false };

    case 'orderTemplate_get': //get orderTemplate get 
      return { ...state, data: action.data, loading: true };
    case 'orderTemplate_get_response':
      return { ...state, orderTemplate_get_res: action.orderTemplate_get_res, loading: false };

    case 'orderTemplate_update': // orderTemplate detail update
      return { ...state, data: action.data, id: action.id, loading: true };
    case 'orderTemplate_update_response':
      return { ...state, orderTemplate_update_res: action.orderTemplate_update_res, loading: false };

    case 'orderTemplate_upload': //order template upload 
      return { ...state, file: action.file, loading: true };
    case 'orderTemplate_upload_response':
      return { ...state, orderTemplate_upload_res: action.orderTemplate_upload_res, loading: false };

    case 'orderTemplate_export': //order template export 
      return { ...state, data: action.data, loading: true };
    case 'orderTemplate_export_response':
      return { ...state, orderTemplate_export_res: action.orderTemplate_export_res, loading: false };

    case 'orderTemplate_refresh': //orderTemplate refresh
      return { ...state, data: action.data, loading: true };
    case 'orderTemplate_refresh_response':
      return { ...state, orderTemplate_refresh_res: action.orderTemplate_refresh_res, loading: false };

    case 'orderTemplateDropDownList': //orderTemplate dropdown list
      return { ...state, data: action.data, loading: true };
    case 'orderTemplateDropDownList_response':
      return { ...state, orderTemplateDropDownList_res: action.orderTemplateDropDownList_res, loading: false };

    //orderTemplate ends here

    default:
      return state;

  }
};

export default reducer;
